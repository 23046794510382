// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getPolicyCollections, postPolicyAdd } from '@/services/hotel.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
    if (this.$route.params.hotel_room_id_eq) {
      this.formData[0].createDateItem[1].Object.disabled = true
      this.$refs.alterform.createDate.hotel_room_id = Number(this.$route.params.hotel_room_id_eq)
    }
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getPolicyCollections().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[1].options = res.data.collections.hotels
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = {
        hotel_room_policy: obj
      }
      Object.keys(params.hotel_room_policy).forEach(res => {
        if (typeof params.hotel_room_policy[res] === 'boolean') {
          if (params.hotel_room_policy[res] === true) params.hotel_room_policy[res] = '1'
          if (params.hotel_room_policy[res] === false) params.hotel_room_policy[res] = '0'
        }
      })
      postPolicyAdd(params).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'policyList',
              params: {
                hotel_room_id_eq: this.$route.params.hotel_room_id_eq
              }
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
